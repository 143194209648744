/*
 * This function is designed to extract a set of key values from simple
 * search string params like `?foo=bar`.
 *
 * Calling `searchParamToObject("?foo=bar")`` will result in `{"foo": "bar"}`
 *
 * This does not support fancier or nested objects such as `?foo[bar]=baz` or
 * `?foo=[1,2,3]`
 */
export const searchParamToObject = (searchString) => {
  if (searchString && searchString.length > 0) {
    const stringPairs = searchString.split('?')[1].split('&');


    const keyValuePairs = stringPairs.map(kv => (kv.split('=')));

    return (keyValuePairs.reduce((obj, pair) => {
      const [key, value] = pair;
      // eslint-disable-next-line no-param-reassign
      obj[key] = value;
      return (obj);
    },
    {})
    );
  } return ({});
};

/*
* This function is designed to sanitize urls and domains into just the
* FQDN domain.
*
* It is a bit simplistic and altho it handles a few random cases and non
* matching regex it returns and empty string in failing cases. This is best
* suited when being passed valid URL or domain values.
*
*/
export const urlToFqdn = (url) => {
  if (typeof (url) !== 'string') {
    return ('');
  }

  const regex = /^\s*(https?:\/\/)?([a-z\d]{3,63}\.[a-z\d]+)/i;
  const match = url.match(regex);
  const val = match ? match[2] : '';

  return (val);
};
