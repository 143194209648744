import fetch from 'cross-fetch';
import { urlToFqdn } from 'core/src/utils/url-helper/utils';

const ENDPOINT_HOST =
  process.env.GATSBY_GRAPHQL_APP_API_ENDPOINT ||
  'https://trace-backend-dev.herokuapp.com';

const GRAPHQL_PATH = ENDPOINT_HOST + '/graphql/';

const JSON_HEADER = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const JSON_AUTH_HEADER = authToken => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Bearer ' + authToken,
});

const fetchQuery = async (query, authToken = '') => {
  try {
    const response = await fetch(GRAPHQL_PATH, {
      method: 'POST',
      headers: authToken ? JSON_AUTH_HEADER(authToken) : { ...JSON_HEADER },
      body: JSON.stringify({ query }),
    });
    const result = await response.json();
    if (result?.errors?.length) {
      return {
        error: result.errors[0].message,
        errorMsg: result.errors[0].message,
      };
    } else {
      return result?.data
        ? result.data
        : {
            error: 'Query returned null data.',
          };
    }
  } catch (error) {
    return { error };
  }
};

/* GraphQL Type Fragments */

const ORG_TYPE_STUB = `
  fragment OrgStub on Organization {
    name
    domain
    location {
      state
      address1
      address2
      zipcode
    }
  }
`;

/* GraphQL Queries */

const loginQuery = (email, password) => {
  return `{
    login(user: {password: "${password}", email: "${email}"}) {
      firstName
      lastName
      authToken
    }
  }`;
};

const updateUserPasswordMutation = (email, token, newPassword) => `mutation {
  setNewPassword(
    ${email ? `email: ${email}\n` : ''}
    token:"${token}"
    password: "${newPassword}"
  )
}`;

const requestPasswordResetMutation = email => `mutation {
  passwordForgotten(email: "${email}")
}`;

const createOrganizationMutation = (owner, organization) => ` mutation {
  createOrganization(
    owner: {
      firstName: "${owner.firstName}"
      lastName: "${owner.lastName}"
      email: "${owner.email}"
      password: "${owner.password}"
    }
    organization: {
      ${
        organization.domain ? `domain: "${urlToFqdn(organization.domain)}"` : ''
      }
      name: "${organization.name}"
      roles: "${organization.roles}"
      location: {
        address1: "${organization.location.address1}"
        address2: "${organization.location.address2}"
        zipcode: "${organization.location.zipcode}"
        state: "${organization.location.state}"
      }
    }
  ){ id }
}`;

/* Query Controllers */

export const loginUser = async (email, password, callback) => {
  //console.log('loginUser - creds: ', email, password)
  const user = {};
  const result = await fetchQuery(loginQuery(email, password));

  if (!result.login || !result.login.authToken || !!result.error) {
    user.authError = result.errorMsg
      ? result.errorMsg
      : 'Email or Password Incorrect';
    console.error(
      'traceAPI - loginUser auth error: ',
      result.error ? result.error : 'Uh oh! Unknown Error',
    );
  } else {
    //valid auth
    user.username =
      (result.login.firstName || '') + ' ' + (result.login.lastName || '');
    user.authToken = result.login.authToken;
    // user.lots = await receiveMeOrgLots(result.login.authToken)
    //console.log('traceAPI - loginUser user: ', user)
  }

  if (callback) callback(user);
  return user;
};

export const createOrganization = async (owner, organization) => {
  const result = await fetchQuery(
    createOrganizationMutation(owner, organization),
  );

  console.log('create user org:', result);
};

export const requestPasswordReset = async email => {
  const result = await fetchQuery(requestPasswordResetMutation(email));

  console.log('request reset:', result);

  return result?.passwordForgotten;
};

export const updateUserPassword = async (email, token, newPassword) => {

  console.warn('We have token', token);

  const result = await fetchQuery(
    updateUserPasswordMutation(email, token, newPassword),
  );

  console.log('update user pass:', result);

  if(result.error) {
    return false;
  } else {
    return true;
  }
};

const traceAPI = {
  loginUser,
  createOrganization,
  requestPasswordReset,
  updateUserPassword,
};

export default traceAPI;
