import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';
import { reducer, loadState, userEffects } from './stateMachine';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, loadState());
  useEffect(() => userEffects(state, dispatch), [state]);

  const appState = {
    state,
    dispatch,
  };

  return <Provider value={appState}>{children}</Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
