import { loginUser } from './traceAPI';

const APP_CACHE = 'trace-app';

const isServer = typeof window === 'undefined';

const initGuestState = () => ({
  username: 'guest',
  email: undefined,
  authToken: undefined,
});

const initUserState = (username = '', email, authToken = null) => ({
  username,
  email,
  authToken,
  authError: '',
  isRequestPending: false,
  timestamp: undefined,
});

const userToState = ({ username, email, authToken, lots }) => {
  const state = {
    ...initUserState(username, email, authToken),
    timestamp: lots ? Date.now() : null,
  };
  return state;
};

const persistState = async state => {
  !!state && localStorage.setItem(APP_CACHE, JSON.stringify(state));
};

export const loadState = (state) => {
  if (!isServer) {
    const cachedState = localStorage.getItem(APP_CACHE);
    return cachedState
      ? { ...JSON.parse(cachedState), timestamp: undefined, type: undefined }
      : { ...initGuestState() };
  } else {
    return { ...initGuestState() };
  }
}

export const reducer = (state = loadState(), action = {}) => {
  switch (action.type) {
    case 'requireAuth':
      return {
        ...state,
        type: action.type,
        authError: action.authError || undefined,
        authToken: null, //while null -> login page
        timestamp: null,
      };
    case 'loginUser':
      return {
        ...state,
        type: action.type, //prevents multi clicks
        authToken: null, //while null -> login page
        authError: undefined, //send auth errors as requireAuth action only
        timestamp: null,
        creds: !action.creds?.email
          ? undefined
          : {
              ...action.creds,
            },
      };
    case 'awaitingAuth':
      return {
        ...state,
        type: action.type,
        authToken: null, //while null -> login page
        authError: undefined, //send auth errors as requireAuth action only
        creds: undefined,
        timestamp: Date.now(),
      };
    case 'authUser':
      return {
        ...state,
        type: action.type,
        ...userToState(action.user),
        timestamp: Date.now(),
      };
    case 'releaseAuth':
      return {
        ...state,
        type: action.type,
        ...initGuestState(), //revert to default no auth
      };
    default:
      return state;
  }
};

export const userEffects = (state, dispatch) => {
  if (state.type === 'loginUser' && !!state.creds) {
    //user is logging in
    const { email, password } = state.creds;
    if (!!email && !!password) {
      dispatch({ type: 'awaitingAuth' });
      loginUser(email, password, ({ username, authToken, authError, lots }) =>
        !!authError || !authToken
          ? dispatch({ type: 'requireAuth', authError })
          : dispatch({
              type: 'authUser',
              user: { username, email, authToken, lots },
            }),
      );
    } else {
      dispatch({ type: 'requireAuth' });
    }
  }

  if (!state.creds) persistState(state); //never persist user creds
};

export default reducer;
